// Components
import { Outlet, Route, Routes, useLocation, useParams } from 'react-router-dom';
import ProtectedRoute from './components/utils/ProtectedRoute';
import environment from './environment';
// Pages
import Main from './components/management/Main';
import Login from './components/user/Login';
import AddCompany from './components/company/AddCompany';
import InterfaceSettings from './components/company/InterfaceSettings';
import ManageHardware from './components/management/ManageHardware';
import ManageLicenses from './components/management/ManageLicenses';
import ManageUsers from './components/management/ManageUsers';
import AddLicenses from './components/license/AddLicenses';
import CriticalLicenses from './components/license/CriticalLicenses';
import EditLicense from './components/license/EditLicense';
import ReplaceFlyidLicense from './components/license/ReplaceFlyidLicense';
import AddUser from './components/user/AddUser';
import ChangePassword from './components/user/ChangePassword';
import ReplaceModerator from './components/user/ReplaceModerator';
import UserProfile from './components/user/UserProfile';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RouteDebug: React.FC = (props) => {
  const params = useParams();
  const location = useLocation();
  console.log({ params, location, props });
  return <Outlet />;
};

const routes = () => {
  return (
    <Routes>
      {/* <Route element={<RouteDebug />}> */}
      {/* Auth Routes */}
      <Route path="/login" element={<Login />} />
      {/* User Routes */}
      <Route element={<ProtectedRoute />}>
        <Route path="/manageUsers" element={<ManageUsers />} />
        <Route path="/addUser" element={<AddUser />} />
        <Route path="/profile/:uid" element={<UserProfile />} />
        <Route path="/changepw" element={<ChangePassword />} />
        <Route path="/replaceModerator/:uid" element={<ReplaceModerator />} />
        {/* Company Routes */}
        <Route path="/addCompany" element={<AddCompany />} />
        {!environment.isV1 && <Route path="/interfaceSettings" element={<InterfaceSettings />} />}
        {/* Licenses Routes */}
        <Route path="/manageLicenses" element={<ManageLicenses />} />
        <Route path="/manageHardware" element={<ManageHardware />} />
        <Route path="/criticalLicenses" element={<CriticalLicenses />} />
        <Route path="/addLicenses" element={<AddLicenses />} />
        <Route path="/replaceFlyidLicense" element={<ReplaceFlyidLicense />} />
        <Route path="/license/:licenseId" element={<EditLicense />} />
        {/* Main */}
        <Route path="/" element={<Main />} />
      </Route>
      {/* </Route> */}
    </Routes>
  );
};

export default routes;
