import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  Container,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Skeleton,
  TextField,
  Typography
} from '@mui/material';
import { MaybeKeyUserPublic, PermissionsProps } from 'flyid-core/dist/Database/Models';
import {
  getCompaniesCol,
  getMaybeKeyUserProfileDoc,
  getUserProfileDoc
} from 'flyid-core/dist/Util/database';
import MultiSelectButton from 'flyid-ui-components/dist/utils/MultiSelectButton';
import { isEmpty } from 'lodash';
import React, { FormEvent, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useIntl } from 'react-intl';
import { Link, Navigate, useParams } from 'react-router-dom';
import { auth } from 'src/firebase/firebase';
import { buildCollectionRef, buildDocumentRef, querySnapToMap } from 'src/firebase/firestore';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import useStateReducer from 'src/hooks/useStateReducer';
import { Actions } from 'src/redux/actions/actionTypes';
import { EditUserParams, fetchProfileImages } from 'src/redux/actions/userActions';
import { MyDialogState, updateUi } from 'src/redux/reducers/uiReducer';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import {
  isAssistantProf,
  isKeyUserProf,
  isModeratorProf,
  isPilotProf
} from 'src/util/helpers/user';
import { decodeText } from 'src/util/web';
import { urlDataReader } from 'src/workers/fileWorkerApi';
import noImg from '../../assets/images/avatar-no-img.png';
import LoadingButton from '../widgets/LoadingButton';
import { useCollectionOnce, useDocumentData } from 'flyid-ui-components/dist/hooks/firestoreHooks';

const useStyles = appMakeStyles(({ resizableContainer, spacing }) => ({
  container: {
    ...resizableContainer(2),
    marginLeft: 0
  },
  mainGrid: {
    minWidth: spacing(50),
    maxWidth: spacing(80)
  },
  titleContainer: {
    maxWidth: spacing(80)
  },
  margin: {
    marginBottom: spacing(1.5)
  },
  button: {
    marginTop: spacing(1)
  },
  profileImage: {
    width: spacing(20),
    height: spacing(20)
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: spacing(0.5)
  }
}));

const initialState = {
  firstName: '',
  lastName: '',
  employeeId: '',
  email: '',
  permissions: [] as PermissionsProps,
  authDomains: [] as string[],
  authLicenses: undefined as string[] | undefined,
  profilePicFile: undefined as string | undefined,
  companies: [] as string[]
};
type State = typeof initialState;

const PermissionsKeys: PermissionsProps = ['pilot', 'assistant', 'moderator', 'keyUser', 'checker'];
const readPermissionsIntoArray = (profile: MaybeKeyUserPublic) => {
  const permissions: PermissionsProps = [];
  if (!isEmpty(profile)) {
    PermissionsKeys.forEach((perm) => {
      const permIsGiven = profile[perm];
      if (permIsGiven) {
        permissions.push(perm);
      }
    });
  }
  return permissions;
};

const UserProfile: React.FC = () => {
  const classes = useStyles();
  const { text, spacing, select } = useAppTheme();
  const { $t } = useIntl();
  const dispatch = useAppDispatch();

  const [state, setState] = useStateReducer<State>(initialState);

  const { uid: paramUid } = useParams<UserMatchParams>();
  const [user] = useAuthState(auth);
  const myUid = user!.uid;

  const isOwnProfile = myUid === paramUid;

  // Fallback to home if paramUid is missing
  if (!paramUid) return <Navigate replace to="/" />;

  const [userProfile, loadingUserProfile, errorUserProfile] = useDocumentData(
    buildDocumentRef(getMaybeKeyUserProfileDoc(paramUid)),
    'userProfile'
  );

  const userIsMod = isModeratorProf(userProfile);
  const userIsPilotOrAssistant = isPilotProf(userProfile) || isAssistantProf(userProfile);
  const userIsKeyUser = isKeyUserProf(userProfile);

  const { ui, isPin, usesAuthProvider, userData } = useAppSelector((s) => {
    let isPin = false;
    let usesAuthProvider = false;
    // Users that have pin are non-key-users that are pilots or checkers and
    // are authenticated through some SSO provider
    if (!!userProfile) {
      const provider = s.firestore.authProviders?.providers?.[state.companies[0]]?.provider;
      usesAuthProvider = !!provider;
      isPin = Boolean(!userIsKeyUser && provider && (userProfile?.pilot || userProfile?.checker));
    }
    return { ui: s.ui, isPin, usesAuthProvider, userData: s.user };
  });

  const hasParent = userProfile?.parent;

  const [parentUser, loadingParent] = useDocumentData(
    hasParent ? buildDocumentRef(getUserProfileDoc(hasParent)) : undefined,
    'parent'
  );

  const [companiesQS, loadingCompanies] = useCollectionOnce(
    buildCollectionRef(getCompaniesCol()),
    'companies'
  );
  const companiesMap = querySnapToMap(companiesQS) ?? {};
  const availbleCompanies = Object.keys(companiesMap);

  const profileImageData = userData.profilePics[paramUid];
  const profileImage = state.profilePicFile || (profileImageData && profileImageData.src) || noImg;

  const showPasswordResetConfirmation = () => {
    const { firstName, lastName } = state;

    dispatch(
      updateUi({
        dialog: new MyDialogState({
          title: $t({ id: `prof.${isPin ? 'pin' : 'pw'}ResetTitle` }),
          message: $t(
            { id: `prof.${isPin ? 'pin' : 'pw'}ResetMsg` },
            {
              name: (
                <b key="uob0">
                  {firstName} {lastName}
                </b>
              ),
              nl: <br key="upnl0" />
            }
          ),
          useCheckbox: false,
          show: true
        }).setConfirmAction(Actions.RESET_USER_PASSWORD, { uid: paramUid, isPin })
      })
    );
  };

  // Fetch profile picture if not yet
  useEffect(() => {
    if (userProfile && paramUid && !profileImageData?.isLoading && !profileImageData?.isLoaded) {
      dispatch(
        fetchProfileImages({
          profileUids: [paramUid],
          company: userProfile.company,
          isThumb: false
        })
      );
    }
  }, [userProfile, paramUid, profileImageData]);

  useEffect(() => {
    if (userProfile) {
      setState({
        firstName: userProfile.firstName,
        lastName: userProfile.lastName,
        email: userProfile.email,
        employeeId: userProfile.employeeId,
        permissions: readPermissionsIntoArray(userProfile),
        authDomains: userProfile.authDomains,
        authLicenses: userProfile.authLicenses,
        companies: userIsKeyUser
          ? (userProfile.company as string[])
          : [userProfile.company as string]
      });
    }
  }, [userProfile]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (userProfile) {
      const company = userProfile.company;
      const data: EditUserParams = {
        uid: paramUid,
        company: userIsKeyUser ? (company as string[])[0] : (company as string),
        email: state.email,
        firstName: state.firstName,
        lastName: state.lastName,
        employeeId: state.employeeId,
        profilePicFile: state.profilePicFile,
        authDomains: state.authDomains,
        authLicenses: state.authLicenses,
        moderator: !!state.permissions.includes('moderator'),
        assistant: !!state.permissions.includes('assistant'),
        pilot: !!state.permissions.includes('pilot'),
        checker: !!state.permissions.includes('checker'),
        keyUser: userIsKeyUser
      };

      if (userIsKeyUser) {
        data.companies = state.companies;
      }

      dispatch(
        updateUi({
          dialog: new MyDialogState({
            title: $t({ id: 'admin.editUsrConfTitle' }),
            message: $t(
              { id: 'admin.editUsrConfMsg' },
              {
                name: (
                  <b key="uob0">
                    {data.firstName} {data.lastName}
                  </b>
                )
              }
            ),
            show: true
          }).setConfirmAction(Actions.EDIT_USER, data)
        })
      );
    }
  };

  /* istanbul ignore next */
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();

    const file = e.target.files?.[0];
    if (file) {
      urlDataReader(file, {
        onload: (result) => {
          const src = result
            ? typeof result === 'string'
              ? result
              : decodeText(result)
            : undefined;
          setState({ profilePicFile: src });
        }
      });
    }
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setState({ [e.target.name]: e.target.value });
  };

  const handleMultipleSelectChange = (e: SelectChangeEvent<string[]>) => {
    setState({ [e.target.name]: e.target.value });
  };

  return (
    <>
      {!errorUserProfile ? (
        <form onSubmit={handleSubmit}>
          <Container className={classes.container}>
            {!loadingUserProfile && !!userProfile ? (
              <>
                <Grid container className={classes.titleContainer}>
                  <Typography variant="h4" sx={text.title}>
                    {$t(
                      { id: 'admin.editUsrTitle' },
                      { name: `${userProfile.firstName} ${userProfile.lastName}` }
                    )}
                  </Typography>
                  <Typography variant="subtitle1" sx={text.subtitle}>
                    {$t({ id: 'admin.editUsrSubtitle' })}
                  </Typography>
                </Grid>

                <Grid container spacing={2} className={classes.mainGrid}>
                  {/* Profile picture container */}
                  <Grid container item spacing={2} direction="column" xs={4} alignItems="center">
                    {/* TODO: Add skeleton loader for profile image while fetching */}
                    <Grid item>
                      <Avatar
                        src={profileImage}
                        data-testid="profile-image"
                        className={classes.profileImage}
                        alt={$t({ id: 'altUserProfileImage' })}
                      />
                    </Grid>

                    <Grid item>
                      <Box
                        component="input"
                        accept="image/jpeg, image/png"
                        sx={{ display: 'none' }}
                        id="profile-image-btn"
                        data-testid="profile-image-btn"
                        type="file"
                        onChange={handleFileChange}
                      />
                      <label htmlFor="profile-image-btn">
                        <Button
                          variant="outlined"
                          component="span"
                          disabled={ui.loadingButton.isUserActionLoading}
                        >
                          {$t({ id: 'prof.chgPicture' })}
                        </Button>
                      </label>
                    </Grid>
                  </Grid>

                  <Grid container item spacing={1} xs={8} alignContent="flex-start">
                    {/* First and Last names */}
                    <Grid item xs={6}>
                      <TextField
                        required
                        fullWidth
                        variant="outlined"
                        id="firstName"
                        data-testid="first-name"
                        name="firstName"
                        type="text"
                        label={$t({ id: 'firstName' })}
                        value={state.firstName}
                        onChange={handleTextChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        required
                        fullWidth
                        variant="outlined"
                        id="lastName"
                        data-testid="last-name"
                        name="lastName"
                        type="text"
                        label={$t({ id: 'lastName' })}
                        value={state.lastName}
                        onChange={handleTextChange}
                      />
                    </Grid>

                    {/* EmployeeId and email */}
                    <Grid item xs={4}>
                      <TextField
                        required
                        fullWidth
                        variant="outlined"
                        id="employeeId"
                        data-testid="employee-id"
                        name="employeeId"
                        type="text"
                        label={$t({ id: 'employeeId' })}
                        value={state.employeeId}
                        onChange={handleTextChange}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        required
                        fullWidth
                        variant="outlined"
                        id="email"
                        name="email"
                        data-testid="email"
                        type="email"
                        label={$t({ id: 'email' })}
                        value={state.email}
                        onChange={handleTextChange}
                      />
                    </Grid>

                    {/* Permissions */}
                    <Grid item xs={12}>
                      <FormControl fullWidth sx={{ mt: 0.5 }} error={isEmpty(state.permissions)}>
                        <InputLabel id="permissions-label">{$t({ id: 'permissions' })}</InputLabel>
                        <Select
                          labelId="permissions-label"
                          id="permissions"
                          data-testid="permissions-select"
                          name="permissions"
                          disabled={userIsKeyUser}
                          multiple
                          className={classes.margin}
                          value={state.permissions}
                          onChange={handleMultipleSelectChange}
                          renderValue={(selected) => (
                            <div className={classes.chips}>
                              {selected.map((value) => (
                                <Chip key={value} label={$t({ id: value })} />
                              ))}
                            </div>
                          )}
                          MenuProps={select.getMenuProps()}
                          input={<OutlinedInput label={$t({ id: 'permissions' })} />}
                        >
                          {PermissionsKeys.map((perm) => (
                            <MenuItem
                              key={perm}
                              value={perm}
                              disabled={perm === 'moderator' || perm === 'keyUser'}
                            >
                              <Checkbox checked={state.permissions.indexOf(perm) > -1} />
                              <ListItemText primary={$t({ id: perm })} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* AuthDomains */}
                  {!loadingUserProfile &&
                  !loadingParent &&
                  !userIsKeyUser &&
                  (userIsPilotOrAssistant || userIsMod) ? (
                    <Grid item xs={12}>
                      <FormControl required fullWidth error={isEmpty(state.authDomains)}>
                        <InputLabel id="authdomains-label">{$t({ id: 'authDomains' })}</InputLabel>
                        <Select
                          fullWidth
                          labelId="authdomains-label"
                          id="authdomains"
                          name="authDomains"
                          data-testid="authdomains-select"
                          multiple
                          disabled={userIsMod}
                          value={state.authDomains}
                          onChange={handleMultipleSelectChange}
                          renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: spacing(0.5) }}>
                              {selected.map((domain) => (
                                <Chip
                                  key={`rend${domain}`}
                                  label={domain}
                                  className={classes.chip}
                                />
                              ))}
                            </Box>
                          )}
                          input={<OutlinedInput label={$t({ id: 'authDomains' })} />}
                          MenuProps={select.getMenuProps()}
                        >
                          {userIsPilotOrAssistant &&
                            parentUser?.authDomains.map((domain) => (
                              <MenuItem key={`men${domain}`} value={domain}>
                                <Checkbox checked={state.authDomains.indexOf(domain) > -1} />
                                <ListItemText primary={domain} />
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : null}

                  {/* Authlicenses */}
                  {!loadingUserProfile && userIsMod ? (
                    <Grid item xs={12}>
                      <FormControl required fullWidth error={isEmpty(state.authLicenses)}>
                        <InputLabel id="authLilenses-label">
                          {$t({ id: 'authLicenses' })}
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="authlicenses-label"
                          id="authlicenses"
                          name="authlicenses"
                          data-testid="authlicenses-select"
                          multiple
                          className={classes.margin}
                          disabled={userIsMod}
                          value={Array.isArray(state.authLicenses) ? state.authLicenses : []}
                          onChange={handleMultipleSelectChange}
                          renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: spacing(0.5) }}>
                              {selected.map((license) => (
                                <Chip
                                  key={`rend${license}`}
                                  label={license}
                                  className={classes.chip}
                                />
                              ))}
                            </Box>
                          )}
                          input={<OutlinedInput label={$t({ id: 'authLicenses' })} />}
                          MenuProps={select.getMenuProps()}
                        ></Select>
                      </FormControl>
                    </Grid>
                  ) : null}

                  {/* Companies (keyUser access) */}
                  {!loadingUserProfile && !loadingCompanies && userIsKeyUser ? (
                    <Grid item xs={12}>
                      <FormControl
                        fullWidth
                        error={isEmpty(state.companies)}
                        className={classes.margin}
                      >
                        <InputLabel id="companies-select-label">
                          {$t({ id: 'companies' })}
                        </InputLabel>
                        <MultiSelectButton
                          options={availbleCompanies}
                          onChange={(e) =>
                            handleMultipleSelectChange({
                              target: { name: 'companies', value: e }
                            } as SelectChangeEvent<string[]>)
                          }
                          initialSelection={state.companies}
                          selectProps={{
                            labelId: 'companies-select-label',
                            input: <OutlinedInput label={$t({ id: 'companies' })} />
                          }}
                          dataTestId="companies-select"
                        />
                      </FormControl>
                    </Grid>
                  ) : null}

                  <Grid container item xs={12} spacing={2} justifyContent="flex-start">
                    <Grid item>
                      <LoadingButton
                        content={$t({ id: 'saveChanges' })}
                        type="submit"
                        data-testid="submit-btn"
                        isLoading={ui.loadingButton.isUserActionLoading}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        component={Link}
                        data-testid="cancel-btn"
                        to={'/manageusers'}
                        sx={{ ml: spacing(2), height: spacing(5) }}
                        color="error"
                        variant="contained"
                      >
                        {$t({ id: 'cancel' })}
                      </Button>
                    </Grid>

                    {!usesAuthProvider || isPin ? (
                      <Grid item sx={{ ml: 'auto' }}>
                        <Button
                          component={Link}
                          data-testid="rst-chg-pw-btn"
                          to={isOwnProfile ? (isPin ? '/changepin' : '/changepw') : '#'}
                          onClick={isOwnProfile ? () => ({}) : showPasswordResetConfirmation}
                          sx={{ height: spacing(5) }}
                          color="primary"
                          variant="contained"
                        >
                          {$t({
                            id: `prof.${isOwnProfile ? 'chg' : 'rst'}${isPin ? 'Pin' : 'Pw'}`
                          })}
                        </Button>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid container className={classes.titleContainer}>
                  <Grid item xs={12}>
                    <Skeleton
                      data-testid="loading-user"
                      variant="text"
                      height={spacing(8)}
                      width={spacing(80)}
                      animation="wave"
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.margin}>
                    <Skeleton variant="text" height={spacing(4)} animation="wave" />
                  </Grid>
                </Grid>

                <Grid container spacing={2} className={classes.mainGrid}>
                  <Grid container item spacing={2} direction="column" xs={4} alignItems="center">
                    <Grid item>
                      <Skeleton
                        variant="circular"
                        className={classes.profileImage}
                        animation="wave"
                      />
                    </Grid>
                    <Grid item>
                      <Skeleton
                        variant="rounded"
                        height={spacing(5)}
                        width={spacing(20)}
                        animation="wave"
                      />
                      <Grid />
                    </Grid>
                  </Grid>

                  <Grid container item spacing={1} xs={8} alignContent="flex-start">
                    <Grid item xs={6}>
                      <Skeleton variant="rounded" height={spacing(7)} animation="wave" />
                    </Grid>
                    <Grid item xs={6}>
                      <Skeleton variant="rounded" height={spacing(7)} animation="wave" />
                    </Grid>
                    <Grid item xs={4}>
                      <Skeleton variant="rounded" height={spacing(7)} animation="wave" />
                    </Grid>

                    <Grid item xs={8}>
                      <Skeleton variant="rounded" height={spacing(7)} animation="wave" />
                    </Grid>

                    <Grid item xs={12}>
                      <Skeleton
                        variant="rounded"
                        height={spacing(8)}
                        animation="wave"
                        sx={{ mt: spacing(1) }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container item className={classes.mainGrid}>
                    <Grid item xs={12}>
                      {Array.from({ length: 2 }).map((_, index) => (
                        <Box key={`skeleton-${index}`}>
                          <Skeleton
                            variant="rounded"
                            height={spacing(8)}
                            animation="wave"
                            className={classes.margin}
                          />
                        </Box>
                      ))}
                    </Grid>
                  </Grid>

                  <Grid container item xs={12} spacing={2} justifyContent="flex-start">
                    <Grid item>
                      <Skeleton
                        variant="rounded"
                        height={spacing(5)}
                        width={spacing(20)}
                        animation="wave"
                      />
                    </Grid>
                    <Grid item>
                      <Skeleton
                        variant="rounded"
                        height={spacing(5)}
                        width={spacing(20)}
                        animation="wave"
                      />
                    </Grid>
                    <Grid item sx={{ ml: 'auto' }}>
                      <Skeleton
                        variant="rounded"
                        height={spacing(5)}
                        width={spacing(20)}
                        animation="wave"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Container>
        </form>
      ) : (
        <Typography variant="body1">An error occurred: {errorUserProfile.message}</Typography>
      )}
    </>
  );
};

export default UserProfile;
