import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Skeleton,
  Tooltip,
  Typography
} from '@mui/material';
import dayjs from 'dayjs';
import { query, where } from 'firebase/firestore';
import { getCompaniesCol, getOurLicensesCol } from 'flyid-core/dist/Util/database';
import { useCollection } from 'flyid-ui-components/dist/hooks/firestoreHooks';
import DropdownFilter from 'flyid-ui-components/dist/utils/DropdownFilter';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { buildCollectionRef, querySnapToMap } from 'src/firebase/firestore';
import { useAppDispatch } from 'src/hooks/reduxHooks';
import { Actions } from 'src/redux/actions/actionTypes';
import { MyDialogState, updateUi } from 'src/redux/reducers/uiReducer';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import { useStoredState } from 'src/util/web';

const useStyles = appMakeStyles(({ resizableContainer, spacing }) => ({
  container: {
    ...resizableContainer(2),
    marginLeft: 0
  },
  mainGrid: {
    minWidth: spacing(70),
    maxWidth: spacing(80)
  },
  titleContainer: {
    maxWidth: spacing(80)
  },
  margin: {
    marginBottom: spacing(1.5)
  },
  button: {
    marginTop: spacing(1)
  },
  itemText: {
    maxWidth: spacing(60)
  }
}));

const ManageLicenses: React.FC = () => {
  const classes = useStyles();
  const { spacing, text } = useAppTheme();
  const { $t } = useIntl();
  const dispatch = useAppDispatch();

  const [companySelected, setCompanySelected] = useStoredState<string>('companySelect', '');
  const [selection, setSelection] = useState({});
  const [activeFilters, setActiveFilters] = useState<string[]>([]);

  const [companiesQS, loadingCompanies, errorCompanies] = useCollection(
    buildCollectionRef(getCompaniesCol()),
    'companies'
  );
  const companies = querySnapToMap(companiesQS) ?? {};

  const [licensesQS, loadingLicenses, errorLicenses] = useCollection(
    companySelected
      ? query(buildCollectionRef(getOurLicensesCol()), where('company', '==', companySelected))
      : undefined,
    'licenses'
  );
  const licenses = querySnapToMap(licensesQS) ?? {};
  const isError = errorCompanies || errorLicenses;
  const isLoaded = !loadingCompanies && !loadingLicenses && !!licenses && !!companies;

  useEffect(() => clearSelection(), [licensesQS]);

  const handleCompanyChange = (e: SelectChangeEvent<string>) => {
    setCompanySelected(e.target.value);
  };

  const handleToggleCheckbox =
    (licenseId: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelection((oldState) => ({ ...oldState, [licenseId]: event.target.checked }));
    };

  const hasSelection = Object.values(selection).some((e) => e === true);

  const clearSelection = () => setSelection({});

  const showRemoveLicenseDialogConfirmation = () => {
    /* istanbul ignore next */
    if (!hasSelection) return;

    const licenseIds = [
      ...Object.entries(selection)
        .filter(([, v]) => !!v)
        .map(([k]) => k)
    ];

    dispatch(
      updateUi({
        dialog: new MyDialogState({
          title: $t({ id: 'admin.remLicConfTitle' }),
          message: $t({ id: 'admin.remLicConfMsg' }),
          useCheckbox: true,
          checkboxState: false,
          checkboxMessage: <b>{$t({ id: 'admin.remLicConfCheckboxMsg' })}</b>,
          show: true
        }).setConfirmAction(Actions.REMOVE_LICENSE, { licenses: licenseIds })
      })
    );
  };

  /* istanbul ignore next */
  const filteredLicenses = Object.entries(licenses).filter(([, licenseData]) => {
    const { isOurHardware, demonstrationOnly, isActive } = licenseData;

    if (activeFilters.includes('isOurHardware') && !isOurHardware) return false;
    if (activeFilters.includes('demonstrationOnly') && !demonstrationOnly) return false;
    if (activeFilters.includes('isActive') && !isActive) return false;

    return true;
  });

  const handleFilterChange = (selected: string[]) => {
    setActiveFilters(selected);
  };

  const licensesToDisplay = activeFilters.length > 0 ? filteredLicenses : Object.entries(licenses);

  return (
    <>
      {!isError ? (
        <Container className={classes.container}>
          {!loadingCompanies && !!companies ? (
            <>
              <Grid container className={classes.titleContainer}>
                <Typography variant="h4" sx={text.title}>
                  {$t({ id: 'nav.managelicenses' })}
                </Typography>
                <Typography variant="subtitle1" sx={text.subtitle}>
                  {$t({ id: 'admin.manageLicensesSubtitle' })}
                </Typography>
              </Grid>

              <Grid container spacing={2} className={classes.mainGrid}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="company-select-label">{$t({ id: 'admin.company' })}</InputLabel>
                    <Select
                      required
                      labelId="company-select-label"
                      id="company-select"
                      data-testid="company-select"
                      name="company"
                      value={companySelected}
                      onChange={handleCompanyChange}
                      input={<OutlinedInput label={$t({ id: 'admin.company' })} />}
                    >
                      <MenuItem value={''}>
                        <Typography variant="body1">{$t({ id: 'none' })}</Typography>
                      </MenuItem>
                      {Object.keys(companies).map((value, index) => (
                        <MenuItem key={index} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6} sx={{ mt: 1 }}>
                  <DropdownFilter
                    availableFilters={{
                      isOurHardware: $t({ id: 'admin.isOurHardware' }),
                      demonstrationOnly: $t({ id: 'admin.demonstrationOnly' }),
                      isActive: $t({ id: 'admin.isActive' })
                    }}
                    onFilterChange={handleFilterChange}
                    initialState={[]}
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid container className={classes.titleContainer}>
                <Grid item xs={12}>
                  <Skeleton variant="text" height={spacing(10)} animation="wave" />
                  <Skeleton variant="text" height={spacing(3)} animation="wave" />
                </Grid>
              </Grid>
              <Grid container className={classes.mainGrid}>
                <Grid item xs={6}>
                  <Skeleton
                    variant="rounded"
                    animation="wave"
                    height={spacing(7)}
                    data-testid="loading-companies"
                  />
                </Grid>
                <Grid item xs={3}>
                  <Skeleton
                    variant="rounded"
                    animation="wave"
                    height={spacing(4)}
                    sx={{ mt: spacing(1.5), ml: spacing(2) }}
                  />
                </Grid>
              </Grid>
            </>
          )}

          {isLoaded ? (
            <Grid container spacing={2} className={classes.mainGrid}>
              <Grid item xs={12}>
                <List
                  data-testid="licenses-list"
                  sx={{
                    marginTop: 0
                  }}
                  subheader={
                    <ListSubheader sx={{ backgroundColor: 'background.default' }}>
                      {$t({ id: 'licenses' })}
                    </ListSubheader>
                  }
                >
                  {!isEmpty(licensesToDisplay) ? (
                    licensesToDisplay.map(([license, licenseData]) => {
                      const { description, expDate } = licenseData;
                      const parseDate = dayjs(expDate).format('DD/MM/YYYY');

                      return (
                        <ListItemButton key={license} alignItems="flex-start">
                          <ListItemIcon>
                            <Checkbox
                              data-testid="license-list-checkbox"
                              edge="start"
                              onChange={handleToggleCheckbox(license)}
                              checked={!!selection[license]}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={license}
                            data-testid="license-list-info"
                            className={classes.itemText}
                            primary={`${license} - ${description}`}
                            secondary={$t({ id: 'admin.manLicExpDate' }, { expDate: parseDate })}
                          />
                          <ListItemSecondaryAction>
                            <Tooltip title={$t({ id: 'edit' })}>
                              <IconButton
                                component={Link}
                                to={`/license/${license}`}
                                data-testid="edit-license-btn"
                                edge="start"
                                aria-label={$t({ id: 'edit' })}
                                sx={{ color: 'info.main' }}
                                size="large"
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          </ListItemSecondaryAction>
                        </ListItemButton>
                      );
                    })
                  ) : companySelected ? (
                    <ListItem key="emptyLicenses" alignItems="flex-start">
                      <ListItemText
                        id="emptyLicenses"
                        primary={$t({ id: 'admin.manLicNoLicenses' })}
                      />
                    </ListItem>
                  ) : null}
                </List>

                {/* Buttons for actions */}
                <Grid
                  container
                  item
                  xs={12}
                  alignItems="center"
                  justifyContent="flex-start"
                  spacing={2}
                >
                  <Grid item>
                    <ListItem>
                      <Button
                        variant="contained"
                        size="medium"
                        color="secondary"
                        aria-label="addLicenses"
                        data-testid="add-license-btn"
                        component={Link}
                        to="/addLicenses"
                        startIcon={<AddCircleIcon />}
                      >
                        {$t({ id: 'admin.addLicenses' })}
                      </Button>
                    </ListItem>
                  </Grid>

                  <Grid item>
                    <Tooltip
                      disableTouchListener={hasSelection}
                      placement="right-start"
                      title={
                        hasSelection ? (
                          ''
                        ) : (
                          <Typography variant="subtitle2">
                            {$t({ id: 'admin.remLicButtonTooltip' })}
                          </Typography>
                        )
                      }
                    >
                      <span>
                        <Button
                          variant="contained"
                          size="medium"
                          color="error"
                          aria-label="removeLicenses"
                          data-testid="remove-license-btn"
                          onClick={showRemoveLicenseDialogConfirmation}
                          disabled={!hasSelection}
                          startIcon={<DeleteSweepIcon />}
                        >
                          {$t({ id: 'admin.removeLicenses' })}
                        </Button>
                      </span>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid container className={classes.mainGrid}>
                <Grid item xs={12}>
                  <Skeleton
                    variant="text"
                    width={spacing(30)}
                    height={spacing(5)}
                    data-testid="loading-license"
                  />
                  {Array.from({ length: 7 }).map((_, index) => (
                    <Grid item xs={12} key={`skeleton-${index}`} className={classes.margin}>
                      <Skeleton variant="rounded" height={spacing(7)} animation="wave" />
                    </Grid>
                  ))}
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  alignItems="center"
                  justifyContent="flex-start"
                  spacing={2}
                >
                  <Grid item>
                    <Skeleton variant="rounded" width={spacing(20)} height={spacing(5)} />
                  </Grid>
                  <Grid item>
                    <Skeleton variant="rounded" width={spacing(20)} height={spacing(5)} />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Container>
      ) : (
        <Typography variant="body1">An error occurred: {isError.message}</Typography>
      )}
    </>
  );
};

export default ManageLicenses;
