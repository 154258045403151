import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  Checkbox,
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Skeleton,
  Tooltip,
  Typography
} from '@mui/material';
import dayjs from 'dayjs';
import { query, where } from 'firebase/firestore';
import { getOurLicensesCol } from 'flyid-core/dist/Util/database';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { buildCollectionRef, querySnapToMap } from 'src/firebase/firestore';
import { useAppDispatch } from 'src/hooks/reduxHooks';
import { Actions } from 'src/redux/actions/actionTypes';
import { MyDialogState, updateUi } from 'src/redux/reducers/uiReducer';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';

const useStyles = appMakeStyles(({ resizableContainer, spacing }) => ({
  container: {
    ...resizableContainer(2),
    marginLeft: 0
  },
  mainGrid: {
    minWidth: spacing(50),
    maxWidth: spacing(80)
  },
  titleContainer: {
    maxWidth: spacing(100)
  },
  margin: {
    marginBottom: spacing(1.5)
  },
  button: {
    marginTop: spacing(1)
  }
}));

const ManageHardware: React.FC = () => {
  const classes = useStyles();
  const { spacing, text } = useAppTheme();
  const { $t } = useIntl();
  const dispatch = useAppDispatch();

  const [selection, setSelection] = useState({});

  const [licensesQS, loadingLicenses, errorLicenses] = useCollection(
    query(buildCollectionRef(getOurLicensesCol()), where('isOurHardware', '==', true))
  );
  const licenses = querySnapToMap(licensesQS) ?? {};

  const isLoaded = !loadingLicenses && !!licenses;

  useEffect(() => clearSelection(), [licensesQS]);

  const handleToggleCheckbox =
    (licenseId: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelection((oldState) => ({ ...oldState, [licenseId]: event.target.checked }));
    };

  const hasSelection = Object.values(selection).some((e) => e === true);

  const clearSelection = () => setSelection({});

  const showRemoveLicenseDialogConfirmation = () => {
    /* istanbul ignore next */
    if (!hasSelection) return;

    const licenseIds = [
      ...Object.entries(selection)
        .filter(([, v]) => !!v)
        .map(([k]) => k)
    ];

    dispatch(
      updateUi({
        dialog: new MyDialogState({
          title: $t({ id: 'admin.remLicConfTitle' }),
          message: $t({ id: 'admin.remLicConfMsg' }),
          useCheckbox: true,
          checkboxState: false,
          checkboxMessage: <b>{$t({ id: 'admin.remLicConfCheckboxMsg' })}</b>,
          show: true
        }).setConfirmAction(Actions.REMOVE_LICENSE, { licenses: licenseIds })
      })
    );
  };

  return (
    <>
      {!errorLicenses ? (
        <Container className={classes.container}>
          {isLoaded ? (
            <>
              <Grid container spacing={1} className={classes.titleContainer}>
                <Typography variant="h4" sx={text.title}>
                  {$t({ id: 'nav.managehardware' })}
                </Typography>
                <Typography variant="subtitle1" sx={{ mb: spacing(2) }}>
                  {$t({ id: 'admin.manageHardwareSubtitle' })}
                </Typography>
              </Grid>

              <Grid container spacing={1} className={classes.mainGrid}>
                <Grid item xs={12}>
                  <List
                    data-testid="licenses-list"
                    subheader={
                      <ListSubheader sx={{ backgroundColor: 'background.default' }}>
                        {$t({ id: 'licensesPanoramaOwned' })}
                      </ListSubheader>
                    }
                  >
                    {!isEmpty(licenses) ? (
                      Object.entries(licenses).map(([license, licenseData]) => {
                        const { description, expDate, company } = licenseData;
                        const parseDate = dayjs(expDate).format('DD/MM/YYYY');

                        return (
                          <ListItemButton
                            key={license}
                            alignItems="flex-start"
                            className={classes.listItem}
                          >
                            <ListItemIcon>
                              <Checkbox
                                data-testid="license-list-checkbox"
                                edge="start"
                                onChange={handleToggleCheckbox(license)}
                                checked={!!selection[license]}
                              />
                            </ListItemIcon>
                            <ListItemText
                              id={license}
                              data-testid="license-list-info"
                              className={classes.itemText}
                              primary={`${license} - ${description}`}
                              secondary={
                                $t({ id: 'admin.company' }) +
                                ': ' +
                                company +
                                ' | ' +
                                $t({ id: 'admin.manLicExpDate' }, { expDate: parseDate })
                              }
                            />
                            <ListItemSecondaryAction>
                              <Tooltip title={$t({ id: 'edit' })}>
                                <IconButton
                                  component={Link}
                                  to={`/license/${license}`}
                                  edge="start"
                                  data-testid="edit-license-btn"
                                  aria-label={$t({ id: 'edit' })}
                                  sx={{ color: 'info.main' }}
                                  size="large"
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                            </ListItemSecondaryAction>
                          </ListItemButton>
                        );
                      })
                    ) : licenses ? (
                      <ListItem
                        key="emptyLicenses"
                        alignItems="flex-start"
                        className={classes.listItem}
                      >
                        <ListItemText
                          id="emptyLicenses"
                          className={classes.itemText}
                          primary={$t({ id: 'admin.manLicNoLicenses' })}
                        />
                      </ListItem>
                    ) : null}
                  </List>

                  {/* Buttons for actions */}
                  <Grid
                    container
                    item
                    xs={12}
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={2}
                  >
                    <Grid item>
                      <ListItem>
                        <Button
                          variant="contained"
                          size="medium"
                          color="secondary"
                          aria-label="addLicenses"
                          component={Link}
                          data-testid="add-license-btn"
                          to="/addLicenses"
                          startIcon={<AddCircleIcon />}
                        >
                          {$t({ id: 'admin.addLicenses' })}
                        </Button>
                      </ListItem>
                    </Grid>

                    <Grid item>
                      <Tooltip
                        disableTouchListener={hasSelection}
                        placement="right-start"
                        title={
                          hasSelection ? (
                            ''
                          ) : (
                            <Typography variant="subtitle2">
                              {$t({ id: 'admin.remLicButtonTooltip' })}
                            </Typography>
                          )
                        }
                      >
                        <span>
                          <Button
                            variant="contained"
                            size="medium"
                            color="error"
                            aria-label="removeLicenses"
                            data-testid="remove-license-btn"
                            onClick={showRemoveLicenseDialogConfirmation}
                            disabled={!hasSelection}
                            startIcon={<DeleteSweepIcon />}
                          >
                            {$t({ id: 'admin.removeLicenses' })}
                          </Button>
                        </span>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid container className={classes.mainGrid} spacing={1}>
              <Grid item xs={12}>
                <Skeleton
                  variant="text"
                  data-testid="loading-license"
                  height={spacing(10)}
                  animation="wave"
                />
                <Skeleton variant="text" height={spacing(3)} animation="wave" />
              </Grid>

              <Grid item xs={12}>
                <Skeleton variant="text" width={spacing(50)} height={spacing(5)} />
                {Array.from({ length: 6 }).map((_, index) => (
                  <Box key={`skeleton-${index}`}>
                    <Skeleton
                      variant="rounded"
                      height={spacing(7)}
                      animation="wave"
                      className={classes.margin}
                    />
                  </Box>
                ))}
              </Grid>
              <Grid
                container
                item
                xs={12}
                alignItems="center"
                justifyContent="flex-start"
                spacing={2}
              >
                <Grid item>
                  <Skeleton variant="rounded" width={spacing(20)} height={spacing(5)} />
                </Grid>
                <Grid item>
                  <Skeleton variant="rounded" width={spacing(20)} height={spacing(5)} />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Container>
      ) : (
        <Typography variant="body1">An error occurred: {errorLicenses.message}</Typography>
      )}
    </>
  );
};

export default ManageHardware;
