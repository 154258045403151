import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Skeleton,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { query, where } from 'firebase/firestore';
import { LicenseType } from 'flyid-core/dist/Database/Models';
import { getCompaniesCol, getUsersCol } from 'flyid-core/dist/Util/database';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { buildCollectionRef, querySnapToMap } from 'src/firebase/firestore';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import useStateReducer from 'src/hooks/useStateReducer';
import { Actions } from 'src/redux/actions/actionTypes';
import { CreateLicensesParams } from 'src/redux/actions/licenseActions';
import { MyDialogState, updateUi } from 'src/redux/reducers/uiReducer';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import { useStoredState } from 'src/util/web';
import LoadingButton from '../widgets/LoadingButton';
// eslint-disable-next-line import/no-unassigned-import
import 'dayjs/locale/pt-br';
import { useCollectionOnce } from 'flyid-ui-components/dist/hooks/firestoreHooks';

const useStyles = appMakeStyles(({ resizableContainer, spacing }) => ({
  container: {
    ...resizableContainer(2),
    marginLeft: 0
  },
  mainGrid: {
    minWidth: spacing(70),
    maxWidth: spacing(70)
  },
  titleContainer: {
    maxWidth: spacing(75)
  },
  margin: {
    marginBottom: spacing(1.5)
  },
  button: {
    marginTop: spacing(1)
  },
  tooltip: {
    alignSelf: 'center',
    marginLeft: spacing(2)
  }
}));

const HumanReadableLicenseTypes = {
  [LicenseType.FLYID]: 'Fly.id',
  [LicenseType.FLYID_PILOT]: 'Fly.id Pilot',
  [LicenseType.COUNTID]: 'Count.id'
};

const initialLicenseData = {
  owner: '',
  expDate: dayjs(new Date()),
  droneSerials: [] as string[], // flyid/pilot only
  licenseType: LicenseType.FLYID,
  maxDevices: 3, // countid only
  description: '',
  spotOnly: false,
  scanditApiKey: '',
  hasMatrixScan: false,
  useTestsLicense: false,
  isOurHardware: false,
  isActive: false,
  demonstrationOnly: false
};
type State = typeof initialLicenseData;

const AddLicense: React.FC = () => {
  const classes = useStyles();
  const { spacing, text, select } = useAppTheme();
  const { $t } = useIntl();
  const dispatch = useAppDispatch();

  const { ui } = useAppSelector((s) => ({
    ui: s.ui
  }));

  const [state, setState] = useStateReducer<State>(Object.assign({}, initialLicenseData));
  const [customScanditApiKey, setCustomScanditApiKey] = useState(false);
  const [companySelected, setCompanySelected] = useStoredState<string>('companySelect', '');
  const isCountIdLicense = state.licenseType === LicenseType.COUNTID;
  const maxExpDate: Dayjs | undefined = state.demonstrationOnly
    ? dayjs().add(3, 'month')
    : dayjs('2222-12-31');

  const [companiesQS, loadingCompanies, errorCompanies] = useCollectionOnce(
    buildCollectionRef(getCompaniesCol()),
    'companies'
  );
  const companies = querySnapToMap(companiesQS) ?? {};

  const [moderatorsQS, loadingModerators, errorModerators] = useCollectionOnce(
    companySelected
      ? query(
          buildCollectionRef(getUsersCol()),
          where('company', '==', companySelected),
          where('moderator', '==', true)
        )
      : undefined,
    'moderators'
  );
  const moderators = querySnapToMap(moderatorsQS) ?? {};

  const isModeratorLoaded = !loadingModerators && !!moderators;
  const isCompanyLoaded = !loadingCompanies && !!companies;
  const isLoaded = isCompanyLoaded && isModeratorLoaded;
  const isError = errorCompanies || errorModerators;

  const restrictBeforeChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();

    const allowedChars = '[a-zA-Z0-9\n-]';
    const modValue = e.target.value
      .split('')
      .filter((c) => c.match(allowedChars))
      .join('');
    setState({ [e.target.name]: modValue });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ [e.target.name]: e.target.checked });
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;

    const changeEffects = {} as Partial<State>;
    if (name === 'licenseType') {
      changeEffects.useTestsLicense =
        (value as LicenseType) === LicenseType.FLYID_PILOT ? true : false;
    }

    setState({ [name]: value, ...changeEffects });
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setState({ [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const { droneSerials } = state;
    const str = droneSerials.toString();
    const serials = str.split(/\r?\n/);

    const licenseData: CreateLicensesParams = {
      company: companySelected,
      owner: state.owner,
      licenseType: state.licenseType,
      description: state.description,
      expDate: state.expDate.format('DD/MM/YYYY'),
      hasMatrixScan: state.hasMatrixScan,
      scanditApiKey: state.scanditApiKey,
      useTestsLicense: state.useTestsLicense,
      isOurHardware: state.isOurHardware,
      isActive: state.isActive,
      demonstrationOnly: state.demonstrationOnly,
      maxDevices: isCountIdLicense ? state.maxDevices : undefined,
      spotOnly: state.spotOnly,
      droneSerials: isCountIdLicense ? undefined : serials
    };

    dispatch(
      updateUi({
        dialog: new MyDialogState({
          title: $t({ id: 'admin.addLicenseConfTitle' }),
          message: $t({ id: 'admin.addLicenseConfMsg' }),
          show: true
        }).setConfirmAction(Actions.CREATE_LICENSES, licenseData)
      })
    );
  };

  return (
    <>
      {!isError ? (
        <form onSubmit={handleSubmit}>
          <Container className={classes.container}>
            {isCompanyLoaded ? (
              <>
                <Grid container className={classes.titleContainer}>
                  <Typography variant="h4" sx={text.title}>
                    {$t({ id: 'admin.addLicense' })}
                  </Typography>
                  <Typography variant="subtitle1" sx={text.subtitle}>
                    {$t({ id: 'admin.addLicenseSubtitle' })}
                  </Typography>
                </Grid>

                <Grid container spacing={1} className={classes.mainGrid}>
                  {/* Company selector */}
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="company-select-label">
                        {$t({ id: 'admin.company' })}
                      </InputLabel>
                      <Select
                        required
                        labelId="company-select-label"
                        data-testid="company-select"
                        className={classes.margin}
                        id="company-select"
                        name="company"
                        onChange={(e) => setCompanySelected(e.target.value)}
                        value={companySelected ?? ''}
                        input={<OutlinedInput label={$t({ id: 'admin.company' })} />}
                        MenuProps={select.getMenuProps()}
                      >
                        {Object.keys(companies).map((company, index) => {
                          return (
                            <MenuItem key={index} value={company}>
                              {company}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid container spacing={2} className={classes.mainGrid}>
                  <Grid container item xs={12} spacing={2} alignContent="flex-start">
                    <Grid item xs={12}>
                      <Skeleton
                        data-testid="loading-companies"
                        variant="text"
                        height={spacing(10)}
                        animation="wave"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Skeleton
                        variant="text"
                        height={spacing(3)}
                        animation="wave"
                        className={classes.margin}
                      />
                    </Grid>

                    <Grid item xs={12} className={classes.margin}>
                      <Skeleton variant="rounded" height={spacing(8)} animation="wave" />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}

            {isLoaded && isModeratorLoaded ? (
              <>
                {/* Owner/Moderator selector */}
                <Grid container spacing={1} className={classes.mainGrid}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="moderator-select-label">{$t({ id: 'moderator' })}</InputLabel>
                      <Select
                        labelId="moderator-select-label"
                        id="moderator-select"
                        data-testid="moderator-select"
                        className={classes.margin}
                        required
                        name="owner"
                        disabled={!companySelected}
                        onChange={handleSelectChange}
                        value={state.owner ?? ''}
                        input={<OutlinedInput label={$t({ id: 'moderator' })} />}
                        MenuProps={select.getMenuProps()}
                      >
                        <MenuItem value={''} disabled>
                          <Typography variant="body1">{$t({ id: 'none' })}</Typography>
                        </MenuItem>
                        {Object.entries(moderators).map(([uid, userData], index) => {
                          return (
                            <MenuItem key={index} value={uid}>
                              {userData.firstName} {userData.lastName} ({uid})
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid container spacing={2} className={classes.mainGrid}>
                <Grid item xs={12} className={classes.margin}>
                  <Skeleton
                    variant="rounded"
                    data-testid="skeleton-moderators"
                    height={spacing(7)}
                    animation="wave"
                  />
                </Grid>
              </Grid>
            )}

            {/* License type selector */}
            {isCompanyLoaded ? (
              <Grid container spacing={1} className={classes.mainGrid}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="license-type-select-label">
                      {$t({ id: 'admin.licenseType' })}
                    </InputLabel>
                    <Select
                      labelId="license-type-select-label"
                      id="license-type-select"
                      data-testid="license-type-select"
                      required
                      name="licenseType"
                      disabled={!companySelected}
                      onChange={handleSelectChange}
                      value={state.licenseType}
                      input={<OutlinedInput label={$t({ id: 'admin.licenseType' })} />}
                      MenuProps={select.getMenuProps()}
                    >
                      {Object.values(LicenseType).map((licenseType, index) => (
                        <MenuItem key={index} value={licenseType}>
                          {HumanReadableLicenseTypes[licenseType]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Description */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="description"
                    data-testid="license-description"
                    name="description"
                    value={state.description}
                    type="text"
                    onChange={handleTextChange}
                    label={$t({ id: 'description' })}
                  />
                </Grid>

                {/* isOurHardware, isActive and demonstrationOnly */}
                <Grid container item spacing={2} xs={12} alignContent="flex-start">
                  {!isCountIdLicense ? (
                    <Grid item xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.isOurHardware}
                            data-testid="license-our-hardware"
                            name="isOurHardware"
                            onChange={handleCheckboxChange}
                          />
                        }
                        label={
                          <Typography variant="body2">
                            {$t({ id: 'admin.isOurHardware' })}
                          </Typography>
                        }
                      />
                    </Grid>
                  ) : null}
                  <Grid item xs={!isCountIdLicense ? 4 : 5}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.isActive}
                          data-testid="license-is-active"
                          name="isActive"
                          onChange={handleCheckboxChange}
                        />
                      }
                      label={
                        <Typography variant="body2">{$t({ id: 'admin.isActive' })}</Typography>
                      }
                    />
                  </Grid>
                  <Grid item xs={!isCountIdLicense ? 4 : 5}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.demonstrationOnly}
                          data-testid="license-dmst-only"
                          name="demonstrationOnly"
                          onChange={handleCheckboxChange}
                        />
                      }
                      label={
                        <Typography variant="body2">
                          {$t({ id: 'admin.demonstrationOnly' })}
                        </Typography>
                      }
                    />
                  </Grid>
                </Grid>

                {/* Expiration, spotonly and max devices  */}
                <Grid container item spacing={2} xs={12} alignItems="center">
                  <Grid item xs={isCountIdLicense ? 5 : 6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                      <DatePicker
                        label={$t({ id: 'expirationDate' })}
                        value={state.expDate}
                        onChange={(value) => setState({ expDate: value ?? undefined })}
                        maxDate={maxExpDate}
                        disablePast
                        slotProps={{
                          textField: {
                            inputProps: { 'data-testid': 'license-exp-date' }
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={isCountIdLicense ? 4.5 : 6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.spotOnly}
                          name="spotOnly"
                          data-testid="license-spot-only"
                          onChange={handleCheckboxChange}
                        />
                      }
                      label={
                        <Typography variant="body2">{$t({ id: 'spotOnlyLicense' })}</Typography>
                      }
                    />
                    <Tooltip
                      disableTouchListener
                      title={
                        <Typography variant="subtitle2">
                          {$t({ id: 'admin.spotOnlyTooltip' })}
                        </Typography>
                      }
                    >
                      <HelpOutlineIcon fontSize="medium" sx={{ color: 'info.dark' }} />
                    </Tooltip>
                  </Grid>
                  {isCountIdLicense && (
                    <Grid item xs={2.5}>
                      <TextField
                        fullWidth
                        required
                        data-testid="license-max-devices"
                        id="maxDevices"
                        name="maxDevices"
                        type="number"
                        label={$t({ id: 'admin.maxDevices' })}
                        value={state.maxDevices}
                        onChange={handleTextChange}
                      />
                    </Grid>
                  )}
                </Grid>

                {/* Drone serial numbers */}
                {!isCountIdLicense && (
                  <Grid container item spacing={1} xs={12} alignContent="flex-start">
                    <>
                      <Grid item xs={11}>
                        <TextField
                          sx={{ mt: spacing(1) }}
                          required
                          multiline
                          fullWidth
                          rows={3}
                          id="droneSerials"
                          data-testid="license-drone-serials"
                          name="droneSerials"
                          value={state.droneSerials}
                          onChange={restrictBeforeChange}
                          type="text"
                          label={$t({ id: 'droneSerials' })}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Tooltip
                          disableTouchListener
                          className={classes.tooltip}
                          title={
                            <Typography variant="subtitle2">
                              {$t({ id: 'admin.droneSerialsTooltip' })}
                              <br />
                              {$t({ id: 'admin.droneSerialsTooltip2' })}
                            </Typography>
                          }
                        >
                          <HelpOutlineIcon fontSize="medium" sx={{ color: 'info.dark' }} />
                        </Tooltip>
                      </Grid>
                    </>
                  </Grid>
                )}

                {/* Use Custom Scandit API Key */}
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={customScanditApiKey}
                        data-testid="license-custom-scandit"
                        onChange={(e) => setCustomScanditApiKey(e.target.checked)}
                      />
                    }
                    label={
                      <Typography variant="body2">
                        {$t({ id: 'admin.customScanditApiKey' })}
                      </Typography>
                    }
                  />
                </Grid>

                {/* Scandit api key data */}
                <Grid container item spacing={2} xs={12} alignContent="flex-start">
                  {customScanditApiKey ? (
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        required
                        id="scanditApiKey"
                        name="scanditApiKey"
                        type="scanditApiKey"
                        data-testid="license-scandit-apikey"
                        label={$t({ id: 'scanditAPIKey' })}
                        value={state.scanditApiKey}
                        onChange={handleTextChange}
                        autoFocus
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={state.licenseType === LicenseType.FLYID_PILOT}
                            checked={state.useTestsLicense}
                            name="useTestsLicense"
                            data-testid="license-use-tests-li"
                            onChange={handleCheckboxChange}
                          />
                        }
                        label={
                          <Typography variant="body2">
                            {$t({ id: 'admin.useTestsLicense' })}
                          </Typography>
                        }
                      />
                    </Grid>
                  )}
                  <Grid item xs={customScanditApiKey ? 4 : 6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.hasMatrixScan}
                          name="hasMatrixScan"
                          data-testid="license-has-matrix"
                          onChange={handleCheckboxChange}
                        />
                      }
                      label={
                        <Typography variant="body2">{$t({ id: 'admin.hasMatrixScan' })}</Typography>
                      }
                    />
                  </Grid>
                </Grid>

                {/* Add button */}
                <Grid item xs={12}>
                  <LoadingButton
                    content={$t({ id: 'admin.addLicense' })}
                    type="submit"
                    data-testid="submit-btn"
                    className={classes.button}
                    isLoading={ui.loadingButton.isLicenseActionLoading}
                  />
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid container spacing={2} className={classes.mainGrid}>
                  <Grid container item xs={12} spacing={2} alignContent="flex-start">
                    {Array.from({ length: 2 }).map((_, index) => (
                      <Grid item xs={12} key={`skeleton-${index}`}>
                        <Skeleton variant="rounded" height={spacing(8)} animation="wave" />
                      </Grid>
                    ))}

                    <Grid item xs={12}>
                      <Skeleton
                        variant="text"
                        data-testid="loading-addlicense"
                        height={spacing(4)}
                        animation="wave"
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <Skeleton variant="rounded" height={spacing(8)} animation="wave" />
                    </Grid>

                    <Grid item xs={6}>
                      <Skeleton variant="rounded" height={spacing(8)} animation="wave" />
                    </Grid>

                    <Grid item xs={12}>
                      <Skeleton variant="rounded" height={spacing(12)} animation="wave" />
                    </Grid>

                    <Grid item xs={8}>
                      <Skeleton variant="text" height={spacing(4)} animation="wave" />
                    </Grid>

                    <Grid item xs={5}>
                      <Skeleton variant="rounded" height={spacing(5)} animation="wave" />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Container>
        </form>
      ) : (
        <Typography variant="body1">An error occurred: {isError.message}</Typography>
      )}
    </>
  );
};

export default AddLicense;
