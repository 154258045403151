import { updateUi } from '../reducers/uiReducer';
import { AppDispatch } from '../store';
import { Actions } from './actionTypes';
import { setupCompany, setupInterfaceSettings } from './companyActions';
import { createLicenses, editLicense, removeLicenses, replaceFlyidLicense } from './licenseActions';
import {
  addUser,
  editUser,
  logoutAction,
  removeUser,
  replaceModerator,
  resetUserPassword
} from './userActions';

/* eslint-disable @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-explicit-any */
export const handleAction = (dispatch: AppDispatch, action: Actions, data: any) => {
  switch (action) {
    // User Actions
    case Actions.LOGOUT:
      dispatch(updateUi());
      dispatch(logoutAction());
      break;
    case Actions.ADD_USER:
      dispatch(updateUi());
      dispatch(addUser(data));
      break;
    case Actions.EDIT_USER:
      dispatch(updateUi());
      dispatch(editUser(data));
      break;
    case Actions.REMOVE_USER:
      dispatch(updateUi());
      dispatch(removeUser(data));
      break;
    case Actions.RESET_USER_PASSWORD:
      dispatch(updateUi());
      dispatch(resetUserPassword(data));
      break;
    case Actions.REPLACE_MODERATOR:
      dispatch(updateUi());
      dispatch(replaceModerator(data));
      break;
    // Company Actions
    case Actions.SETUP_COMPANY:
      dispatch(updateUi());
      dispatch(setupCompany(data));
      break;
    case Actions.SETUP_INTERFACE_SETTINGS:
      dispatch(updateUi());
      dispatch(setupInterfaceSettings(data));
      break;
    // License Actions
    case Actions.CREATE_LICENSES:
      dispatch(updateUi());
      dispatch(createLicenses(data));
      break;
    case Actions.EDIT_LICENSE:
      dispatch(updateUi());
      dispatch(editLicense(data));
      break;
    case Actions.REMOVE_LICENSE:
      dispatch(updateUi());
      dispatch(removeLicenses(data));
      break;
    case Actions.REPLACE_FLYID_LICENSE:
      dispatch(updateUi());
      dispatch(replaceFlyidLicense(data));
      break;
    default:
      return;
  }
};
