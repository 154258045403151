import EditIcon from '@mui/icons-material/Edit';
import ErrorIcon from '@mui/icons-material/Error';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  OutlinedInput,
  Skeleton,
  Tooltip,
  Typography
} from '@mui/material';
import dayjs from 'dayjs';
import { FirestoreError, orderBy, query, where } from 'firebase/firestore';
import { getCompaniesCol, getOurLicensesCol } from 'flyid-core/dist/Util/database';
import { useCollection } from 'flyid-ui-components/dist/hooks/firestoreHooks';
import DropdownFilter from 'flyid-ui-components/dist/utils/DropdownFilter';
import MultiSelectButton from 'flyid-ui-components/dist/utils/MultiSelectButton';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { buildCollectionRef, querySnapToMap } from 'src/firebase/firestore';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import {
  getAndAccumulateQueries,
  getSlicedWhereArrayOperandQuery
} from 'src/util/helpers/database';

const useStyles = appMakeStyles(({ resizableContainer, spacing }) => ({
  container: {
    ...resizableContainer(2),
    marginLeft: 0
  },
  mainGrid: {
    minWidth: spacing(75),
    maxWidth: spacing(75)
  },
  titleContainer: {
    maxWidth: spacing(75)
  },
  margin: {
    marginBottom: spacing(1.5)
  },
  button: {
    marginTop: spacing(1)
  },
  filter: {
    alignContent: 'center',
    marginLeft: spacing(1.5),
    height: spacing(7)
  },
  itemText: {
    margin: spacing(1, 1),
    paddingRight: spacing(5)
  }
}));

type LicenseData = {
  id: string;
  company: string;
  description: string;
  expDate: Date;
};

const CriticalLicenses: React.FC = () => {
  const classes = useStyles();
  const { spacing, text } = useAppTheme();
  const { $t } = useIntl();

  const [selectedCompanies, setSelectedCompanies] = useState<string[]>([]);
  const [licenseDataList, setLicenseDataList] = useState<LicenseData[]>([]);
  const [loadingLicenses, setLoadingLicenses] = useState(true);
  const [errorLicenses, setErrorLicenses] = useState<string | null>(null);
  const [activeFilters, setActiveFilters] = useState<string[]>([]);

  const [companiesQS, loadingCompanies, errorCompanies] = useCollection(
    buildCollectionRef(getCompaniesCol()),
    'companies'
  );

  const companiesMap = querySnapToMap(companiesQS) ?? {};
  const companies = Object.keys(companiesMap);

  const isLicenseLoaded = !loadingLicenses && !!licenseDataList;
  const isCompanyLoaded = !loadingCompanies && !!companies;
  const isLoaded = isCompanyLoaded && isLicenseLoaded;
  const isError = errorCompanies || errorLicenses;

  const currentDate = dayjs().toDate();
  const oneMonthFromNow = dayjs().add(1, 'months').toDate();
  const threeMonthsFromNow = dayjs().add(3, 'months').toDate();

  useEffect(() => {
    setLoadingLicenses(true);

    const queries = getSlicedWhereArrayOperandQuery(
      query(
        buildCollectionRef(getOurLicensesCol()),
        where('expDate', '<=', threeMonthsFromNow),
        orderBy('expDate', 'asc')
      ),
      'company',
      'in',
      selectedCompanies
    );

    getAndAccumulateQueries(queries)
      .then((docSnaps) => {
        const licenses = docSnaps.map((snap) => {
          const licenseData = snap.data();
          const data = {
            id: snap.id,
            company: licenseData.company,
            description: licenseData.description,
            expDate: licenseData.expDate
          } as LicenseData;
          return data;
        });
        setLicenseDataList(licenses);
        setLoadingLicenses(false);
      })
      .catch((err) => {
        if (err instanceof Error) {
          setErrorLicenses(err.message);
        } else {
          /* istanbul ignore next */
          setErrorLicenses('An unknown error occurred');
        }
        setLoadingLicenses(false);
      });
  }, [selectedCompanies]);

  const filteredLicenses = licenseDataList.filter((licenseData) => {
    const { expDate } = licenseData;

    if (activeFilters.includes('all')) return true;
    if (activeFilters.includes('expired') && expDate < currentDate) return true;
    if (
      activeFilters.includes('aboutToExpire') &&
      expDate > currentDate &&
      expDate <= oneMonthFromNow
    )
      return true;
    if (
      activeFilters.includes('expirationIsClose') &&
      expDate > oneMonthFromNow &&
      expDate <= threeMonthsFromNow
    )
      return true;

    return activeFilters.length === 0;
  });

  const handleMultipleSelectChange = (company: string[]) => {
    setSelectedCompanies(company);
  };

  const handleFilterChange = (selected: string[]) => {
    setActiveFilters(selected);
  };

  const licensesToDisplay = activeFilters.length > 0 ? filteredLicenses : licenseDataList;

  return (
    <>
      {!isError ? (
        <Container className={classes.container}>
          {isCompanyLoaded ? (
            <>
              <Grid container className={classes.titleContainer}>
                <Typography variant="h4" sx={text.title}>
                  {$t({ id: 'admin.criticalLicenses' })}
                </Typography>
                <Typography variant="subtitle1" sx={text.subtitle}>
                  {$t({ id: 'admin.criticalLicensesSubtitle' })}
                </Typography>
              </Grid>

              <Grid container className={classes.mainGrid}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="companies-select-label">{$t({ id: 'companies' })}</InputLabel>
                    <MultiSelectButton
                      options={companies}
                      onChange={handleMultipleSelectChange}
                      selectProps={{
                        labelId: 'companies-select-label',
                        input: <OutlinedInput label={$t({ id: 'companies' })} />,
                        inputProps: { 'data-testid': 'companies-select' }
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sx={{ mt: spacing(2) }}>
                  <DropdownFilter
                    availableFilters={{
                      all: $t({ id: 'admin.filterByAll' }),
                      expired: $t({ id: 'expired' }),
                      aboutToExpire: $t({ id: 'aboutToExpire' }),
                      expirationIsClose: $t({ id: 'expirationIsClose' })
                    }}
                    onFilterChange={handleFilterChange}
                    initialState={[]}
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid container spacing={spacing(1)} className={classes.titleContainer}>
                <Grid item xs={12}>
                  <Skeleton
                    variant="text"
                    height={spacing(10)}
                    data-testid="loading-companies"
                    animation="wave"
                  />
                  <Skeleton variant="text" height={spacing(5)} animation="wave" />
                </Grid>
                <Grid item xs={9}>
                  <Skeleton variant="rounded" animation="wave" height={spacing(7)} />
                </Grid>
                <Grid item xs={3}>
                  <Skeleton variant="rounded" animation="wave" height={spacing(7)} />
                </Grid>
              </Grid>
            </>
          )}

          {isLoaded ? (
            <Grid container spacing={2} className={classes.mainGrid}>
              <Grid item xs={12}>
                <List
                  data-testid="crr-licenses-list"
                  subheader={
                    <ListSubheader sx={{ backgroundColor: 'background.default' }}>
                      {!isEmpty(selectedCompanies) ? $t({ id: 'admin.criticalLicenses' }) : null}
                    </ListSubheader>
                  }
                >
                  {!isEmpty(licensesToDisplay) ? (
                    licensesToDisplay.map((licenseData) => {
                      const { description, company, id, expDate } = licenseData;
                      const licenseId = id;
                      const parseDate = dayjs(expDate).format('DD/MM/YYYY');

                      return (
                        <ListItemButton
                          key={licenseId}
                          alignItems="flex-start"
                          className={classes.listItem}
                        >
                          <ListItemText
                            id={licenseId}
                            data-testid="crr-licenses-list-info"
                            className={classes.itemText}
                            primary={$t(
                              { id: 'admin.criticalLicensesListItemText1' },
                              {
                                company: company,
                                licenseId: licenseId
                              }
                            )}
                            secondary={$t(
                              { id: 'admin.criticalLicensesListItemText2' },
                              {
                                description: description,
                                nl: <br key={`${licenseId}nl`} />,
                                expDate: parseDate
                              }
                            )}
                          />
                          <ListItemIcon sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                            {/* Edit Icon */}
                            <Tooltip title={$t({ id: 'edit' })}>
                              <IconButton
                                component={Link}
                                to={`/license/${licenseId}`}
                                data-testid="edit-license-btn"
                                edge="start"
                                aria-label={$t({ id: 'edit' })}
                                sx={{ color: 'info.main', mt: spacing(0.2) }}
                                size="large"
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>

                            {/* Status Icon (Expired, About to Expire, Expiration Close) */}
                            {expDate < currentDate ? (
                              <Tooltip title={$t({ id: 'expired' })}>
                                <ErrorIcon
                                  className={classes.itemIcon}
                                  fontSize="large"
                                  color="error"
                                  data-testid="crr-licenses-expired"
                                />
                              </Tooltip>
                            ) : expDate <= oneMonthFromNow ? (
                              <Tooltip title={$t({ id: 'aboutToExpire' })}>
                                <WarningIcon
                                  className={classes.itemIcon}
                                  fontSize="large"
                                  color="warning"
                                  data-testid="crr-licenses-about-to-expire"
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip title={$t({ id: 'expirationIsClose' })}>
                                <NotificationImportantIcon
                                  className={classes.itemIcon}
                                  fontSize="large"
                                  color="warning"
                                  data-testid="crr-licenses-exp-is-close"
                                />
                              </Tooltip>
                            )}
                          </ListItemIcon>
                        </ListItemButton>
                      );
                    })
                  ) : selectedCompanies.length > 0 ? (
                    <ListItem
                      key="emptyLicenses"
                      alignItems="flex-start"
                      className={classes.listItem}
                    >
                      <ListItemText
                        id="emptyLicenses"
                        className={classes.itemText}
                        primary={$t({ id: 'admin.manLicNoLicenses' })}
                      />
                    </ListItem>
                  ) : null}
                </List>
              </Grid>
            </Grid>
          ) : (
            <Grid container className={classes.mainGrid}>
              <Grid item xs={12} sx={{ mt: spacing(2) }}>
                {Array.from({ length: 6 }).map((_, index) => (
                  <Skeleton
                    key={`skeleton-${index}`}
                    data-testid="loading-crr-license"
                    variant="rounded"
                    height={spacing(10)}
                    animation="wave"
                    className={classes.margin}
                  />
                ))}
              </Grid>
            </Grid>
          )}
        </Container>
      ) : (
        <Typography variant="body1">
          An error occurred: {(isError as FirestoreError).message}
        </Typography>
      )}
    </>
  );
};

export default CriticalLicenses;
