import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { auth } from 'src/firebase/firebase';
import Splash from './Splash';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getLogo } from 'src/assets/assets';

const LandingLoading: React.FC = ({ children }) => {
  const [, authLoading] = useAuthState(auth);
  return JSON.parse(localStorage.getItem('firstTimeInit') || 'false') ? (
    <TransitionGroup>
      <CSSTransition
        key={`lltransition${String(authLoading)}`}
        timeout={{ enter: 1000, exit: 500 }}
        classNames={'fade'}
      >
        {authLoading ? <>{children}</> : <Splash logo={getLogo()} />}
      </CSSTransition>
    </TransitionGroup>
  ) : (
    <>{children}</>
  );
};

export default LandingLoading;
