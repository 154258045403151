import { CollectionReference, DocumentReference, Query } from 'firebase/firestore';
import {
  useCollection as fbUseCollection,
  useCollectionOnce as fbUseCollectionOnce,
  useDocumentData as fbUseDocumentData
} from 'react-firebase-hooks/firestore';

type QueryType<T> = Query<T> | CollectionReference<T> | undefined | null;
type DocReferenceType<T> = DocumentReference<T> | undefined | null;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useCollection = <T>(query: QueryType<T>, _testId: string) => fbUseCollection(query);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useCollectionOnce = <T>(query: QueryType<T>, _testId: string) =>
  fbUseCollectionOnce(query);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useDocumentData = <T>(docRef: DocReferenceType<T>, _testId: string) =>
  fbUseDocumentData(docRef);
