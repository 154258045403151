import {
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Skeleton,
  Typography
} from '@mui/material';
import { query, where } from 'firebase/firestore';
import { getCompaniesCol, getOurLicensesCol } from 'flyid-core/dist/Util/database';
import React from 'react';
import { useCollectionOnce } from 'react-firebase-hooks/firestore';
import { useIntl } from 'react-intl';
import { buildCollectionRef, querySnapToMap } from 'src/firebase/firestore';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import useStateReducer from 'src/hooks/useStateReducer';
import { Actions } from 'src/redux/actions/actionTypes';
import { ReplaceFlyidLicenseParams } from 'src/redux/actions/licenseActions';
import { MyDialogState, updateUi } from 'src/redux/reducers/uiReducer';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import { useStoredState } from 'src/util/web';
import LoadingButton from '../widgets/LoadingButton';

const useStyles = appMakeStyles(({ resizableContainer, spacing }) => ({
  container: {
    ...resizableContainer(2),
    marginLeft: 0
  },
  mainGrid: {
    minWidth: spacing(70),
    maxWidth: spacing(70)
  },
  titleContainer: {
    maxWidth: spacing(75)
  },
  margin: {
    marginBottom: spacing(1.5)
  },
  button: {
    marginTop: spacing(1)
  }
}));

const initialReplaceLicenseData = {
  currentLicense: '',
  newLicense: '',
  deleteCurrent: false
};
type State = typeof initialReplaceLicenseData;

const ReplaceFlyidLicense: React.FC = () => {
  const classes = useStyles();
  const { text, spacing } = useAppTheme();
  const { $t } = useIntl();
  const dispatch = useAppDispatch();

  const { ui } = useAppSelector((s) => ({
    ui: s.ui
  }));

  const [state, setState] = useStateReducer<State>(Object.assign({}, initialReplaceLicenseData));

  const [companySelected, setCompanySelected] = useStoredState<string>('companySelect', '');

  const [companiesQS, loadingCompanies, errorCompanies] = useCollectionOnce(
    buildCollectionRef(getCompaniesCol())
  );
  const companies = querySnapToMap(companiesQS) ?? {};

  const [licensesQS, loadingLicenses, errorLicenses] = useCollectionOnce(
    companySelected
      ? query(buildCollectionRef(getOurLicensesCol()), where('company', '==', companySelected))
      : undefined
  );
  const licenses = querySnapToMap(licensesQS) ?? {};

  const isCompanyLoaded = !loadingCompanies && !!companies;
  const isLicenseLoaded = !loadingLicenses && !!licenses;
  const isError = errorCompanies || errorLicenses;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const licenseData: ReplaceFlyidLicenseParams = {
      currentLicense: state.currentLicense,
      newLicense: state.newLicense,
      deleteCurrent: state.deleteCurrent
    };

    dispatch(
      updateUi({
        dialog: new MyDialogState({
          title: $t({ id: 'admin.replaceLicenseConfTitle' }),
          message: $t(
            { id: 'admin.replaceLicenseConfMsg' },
            {
              currentLicense: (
                <b key={`mdb0${licenseData.currentLicense}`}>{licenseData.currentLicense}</b>
              ),
              newLicense: <b key={`mdb0${licenseData.newLicense}`}>{licenseData.newLicense}</b>
            }
          ),
          show: true
        }).setConfirmAction(Actions.REPLACE_FLYID_LICENSE, licenseData)
      })
    );
  };

  const handleCompanyChange = (e: SelectChangeEvent<string>) => {
    setCompanySelected(e.target.value);
    setState({ ...initialReplaceLicenseData });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ [e.target.name]: e.target.checked });
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    setState({ [e.target.name]: e.target.value });
  };

  return (
    <>
      {!isError ? (
        <form onSubmit={handleSubmit}>
          <Container className={classes.container}>
            {isCompanyLoaded ? (
              <>
                <Grid container className={classes.titleContainer}>
                  <Typography variant="h4" sx={text.title}>
                    {$t({ id: 'admin.replaceFlyidLicense' })}
                  </Typography>
                  <Typography variant="subtitle1" sx={text.subtitle}>
                    {$t({ id: 'admin.replaceLicenseSubtitle' })}
                  </Typography>
                </Grid>

                <Grid container className={classes.mainGrid} spacing={2}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="company-label">{$t({ id: 'admin.company' })}</InputLabel>
                      <Select
                        required
                        labelId="company-label"
                        id="company"
                        data-testid="company-select"
                        name="company"
                        value={companySelected}
                        onChange={handleCompanyChange}
                        input={<OutlinedInput label={$t({ id: 'admin.company' })} />}
                      >
                        <MenuItem value={''} disabled>
                          <Typography variant="body1">{$t({ id: 'none' })}</Typography>
                        </MenuItem>
                        {Object.keys(companies).map((value, index) => {
                          return (
                            <MenuItem key={index} value={value}>
                              {value}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="crlicense-label">
                        {$t({ id: 'admin.currentLicense' })}
                      </InputLabel>
                      <Select
                        labelId="crlicense-label"
                        id="currentlicense"
                        data-testid="crlicense-select"
                        required
                        name="currentLicense"
                        onChange={handleSelectChange}
                        value={state.currentLicense}
                        input={<OutlinedInput label={$t({ id: 'admin.currentLicense' })} />}
                      >
                        <MenuItem value={''} disabled>
                          <Typography variant="body1">{$t({ id: 'none' })}</Typography>
                        </MenuItem>
                        {companySelected &&
                          isLicenseLoaded &&
                          Object.entries(licenses).map(([lid, license]) => {
                            const { description } = license;
                            return (
                              <MenuItem key={lid} value={lid}>{`${description} (${lid})`}</MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="newlicense-label">
                        {$t({ id: 'admin.replacementLicense' })}
                      </InputLabel>
                      <Select
                        labelId="newlicense-label"
                        id="newlicense"
                        data-testid="newlicense-select"
                        disabled={!companySelected || !state.currentLicense}
                        required
                        name="newLicense"
                        onChange={handleSelectChange}
                        value={state.newLicense}
                        input={<OutlinedInput label={$t({ id: 'admin.replacementLicense' })} />}
                      >
                        <MenuItem value={''} disabled>
                          <Typography variant="body1">{$t({ id: 'none' })}</Typography>
                        </MenuItem>
                        {companySelected &&
                          Object.keys(licenses)
                            .filter((l) => !l.match(state.currentLicense))
                            .map((lid) => (
                              <MenuItem value={lid} key={lid}>
                                {`${licenses[lid].description} (${lid})`}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.deleteCurrent}
                          name="deleteCurrent"
                          data-testid="delete-crr"
                          onChange={handleCheckboxChange}
                        />
                      }
                      label={
                        <Typography variant="body2">{$t({ id: 'admin.deleteCurrent' })}</Typography>
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <LoadingButton
                      content={$t({ id: 'admin.replaceFlyidLicense' })}
                      data-testid="submit-btn"
                      type="submit"
                      isLoading={ui.loadingButton.isLicenseActionLoading}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid container spacing={2} className={classes.mainGrid}>
                  <Grid container item xs={12} spacing={2} alignContent="flex-start">
                    <Grid item xs={12}>
                      <Skeleton
                        variant="text"
                        data-testid="loading-fields"
                        height={spacing(10)}
                        animation="wave"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Skeleton
                        variant="text"
                        height={spacing(3)}
                        animation="wave"
                        className={classes.margin}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Skeleton variant="rounded" height={spacing(8)} animation="wave" />
                    </Grid>

                    <Grid item xs={12}>
                      <Skeleton variant="rounded" height={spacing(8)} animation="wave" />
                    </Grid>

                    <Grid item xs={12}>
                      <Skeleton variant="rounded" height={spacing(8)} animation="wave" />
                    </Grid>

                    <Grid item xs={7}>
                      <Skeleton variant="text" height={spacing(5)} animation="wave" />
                    </Grid>

                    <Grid item xs={6}>
                      <Skeleton variant="rounded" height={spacing(6)} animation="wave" />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Container>
        </form>
      ) : (
        <Typography variant="body1">An error occurred: {isError.message}</Typography>
      )}
    </>
  );
};

export default ReplaceFlyidLicense;
